.logo__image {
  width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@font-face {
  font-family: para;
  src: url(./Linotype\ -\ NeuzeitSLTStd-Book.otf);
}
.App {
  text-align: center;
}

.css-1equabv-MuiGrid-root {
  background-color: black !important;
  border-radius: 10 10px 10px 10px !important;
}
.css-gj1fbr-MuiGrid-root {
  background-color: black !important;
  border-radius: 0px 0px 0px 0 !important;
}
.css-k3f0g4-MuiGrid-root {
  background-color: #000;
  /* border-radius: 20px !important; */
  border-left: 2px solid white;
}
.makeStyles-done-4 {
  background-color: #000 !important;
}
* {
  font-family: para;
}
.MuiButton-contained {
  /* color: rgba(0, 0, 0, 0.87); */
  box-shadow: none !important;
  background-color: none !important;
}
.head-solpeepz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative;
  width: 100%; */
}
.head-solpeepz img {
  width: 140px;
  height: auto;
}
body {
  overflow-x: hidden;
  background-color: black;
}
.solana {
  margin: 20px 0 0 0;
}
.css-1f6ngd1 {
  background-color: black !important;
}
.mintContainer {
  margin-top: 70px;
  position: relative;
  text-align: center;
}
.box {
  text-align: left;
  width: 60vw;
  height: auto;
  position: relative;
  left: 50%;
  margin: 2vh 0 5vh 0;
  transform: translateX(-50%);
}
.MuiButton-label {
  font-family: para !important;
  /* color: black !important; */
}
.MuiButton-contained {
  min-width: 250px !important;
  min-height: 50px !important;
  margin: 20px 0 20px 0 !important;
  background-image: url(./button_1.png) !important;
  background-size: cover !important;
  border: none !important;
  background-position: center;
}
.MuiButton-contained:hover {
  border: none !important;
}
.kridlM {
  border: none !important;
}
.kridlM:hover {
  border: none !important;
}
a {
  color: rgb(64, 164, 235);
}
.warning {
  margin: 0px 0 30px 0;
  position: relative;
  text-align: center;
  /* left: ; */
  top: 0px;
}
.your {
  font-size: 2vh;
  position: absolute;
  bottom: 10px;
}
@media screen and (max-width: 800px) {
  .right {
    height: 150px !important;
  }
  .MuiButton-contained {
    min-width: 200px !important;
    min-height: 40px !important;
    margin-bottom: 50px !important;
  }
  .head-solpeepz img {
    width: 80px !important;
    height: auto;
  }
  .MuiButton-label {
    font-size: 12px;
  }
  .kridlM {
    border: none !important;
  }
  .kridlM:hover {
    border: none !important;
  }
  .gif {
    width: 60vw;
    height: auto;
  }
}
@media screen and (max-width: 1200px) {
  .your {
    /* margin-top: 40px !important; */
    bottom: 0;
  }
  .address {
    bottom: 2px !important;
  }
  .right {
    height: 200px !important;
  }
}
.mint__your {
  color: #c4a253;
  font-size: 5vh;
}
.lfg {
  color: #c4a253;
  font-size: 7vh;
}
.lfgImg {
  width: 28vw;
  height: auto;
}
.genesis {
  width: 80%;
  height: auto;
  position: relative;
  left: 0;
}
.left {
  text-align: left;
  width: 100%;
}
.left p {
  font-size: 16px;
  font-family: para;
  color: white;
  font-weight: 400 !important;
  line-height: 10px !important;
}
.left a {
  font-size: 2.3vh;

  font-family: para;
}
.left p::before {
  content: "•	";
  color: #c4a253;
  /* background-color: rebeccapurple; */
}
.whitelist-text {
  font-size: 2.6vh;
}
.solpeepz-math {
  color: rgb(64, 164, 235);
  font-size: 2.3vh;
}
.mintContainer {
  text-align: center;
  color: rgb(64, 164, 235);
}
.mint-your {
  margin-top: 30px;
  width: 100%;
}
.right {
  background-color: white;
  display: flex;
  position: relative;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  padding-bottom: 2vh;
  font-family: para;
}

.minted {
  color: rgb(21, 226, 21);
  font-family: para;
}
.address {
  color: rgb(0, 0, 0);
  font-family: para;
  position: absolute;
  bottom: 25px;
}
.genesisBody {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.face {
  width: 100%;
  position: relative;
  top: 1vh;
  height: auto;
}
.monkey {
  position: absolute;
  right: 0;
  /* top: 0; */
  width: 30vw;
  height: auto;
}
.monkeyL {
  position: absolute;
  left: 0;
  /* top: 0; */
  width: 30vw;
  height: auto;
}
.right div p {
  font-size: 2.3vh;
  font-family: para;
}

@media screen and (max-width: 800px) {
  .your {
    /* margin-top: 40px !important; */
    bottom: 0;
  }
  .address {
    /* font-size: 10px !important; */

    bottom: 2px;
  }
  /* .right div p {
    font-size: 10px;
    font-family: para;
  } */

  .left p {
    font-size: 15px;
    padding: 0 1vh 0 1vh;
    font-family: para;
  }
  .left a {
    font-size: 2.1vh;
    padding: 0 1vh 0 1vh;
    font-family: para;
  }
  .lfgImg {
    width: 100%;
    height: auto;
  }
  .box {
    text-align: left;
    width: 80vw !important;
    height: auto;
    position: relative;
    left: 50%;
    margin: 10vh 0 5vh 0;
    transform: translateX(-50%);
  }
  .your {
    font-size: 2vh;
    margin-top: 2.5vh;
  }
}
@media screen and (max-width: 1024px) {
  .lfgImg {
    width: 100%;
    height: auto;
  }
  .box {
    text-align: left;
    width: 60vw;
    height: auto;
    position: relative;
    left: 50%;
    margin: 10vh 0 5vh 0;
    transform: translateX(-50%);
  }
  .your {
    font-size: 2vh;
  }
}

@media screen and (max-width: 1200px) {
  .mintContainer {
    margin-top: 90px;
    position: relative;
    text-align: center;
  }
  .head-solpeepz img {
    width: 170px;
    height: auto;
  }
  .left p {
    font-size: 16px;
    padding: 0 1vh 0 1vh;
    font-family: para;
    line-height: 22px !important;
  }
}
@media screen and (width: 768px) {
  .head-solpeepz img {
    width: 170px !important;
    height: auto;
  }
}
.banner_message {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-weight: 700;
}
