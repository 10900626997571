@font-face {
  font-family: coolest;
  src: url(../assets/ITCAvantGardePro-Bold.otf);
}
.topbar {
  width: 100%;
  height: 70px;
  z-index: 100;
  background-color: white;
}
.topbar__body {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: white;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  /* margin-top: -10vh; */
  top: 0;
  left: 2.6vw;
}
.top__discordImg {
  width: 150px;
  height: auto;
}
.nav__disc {
  font-family: coolest;
  background-color: white;
  border: 0.25vh solid black;
  border-radius: 3vw;
  color: black;
  font-weight: 700;
  font-size: 14px !important;
  width: 150px;
  height: auto;
  padding: 8px 0 8px 0;
  text-align: center;
}
.nav__disc:hover {
  background-color: black;
  color: white;
}
.centerText {
  margin-top: 14px;
  position: relative;
  left: 1vw;
}
.top__discord {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  left: 6vw;
  top: 15px !important;
}
.invite1 {
  position: absolute;
  top: 10px !important;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 12px !important;
}
.invite3 {
  position: absolute;
  top: 1.2vh !important;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 1.8vh !important;
}
.madfireImg {
  width: 75px;
  height: auto;
}
.madfire {
  position: relative;
  right: 5vw;
  margin-top: 0vh;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.gold__sol {
  margin-top: 11vh;
}
.gold__solpeepz {
  margin-top: 12vh;
}
.top__solpeepz {
  width: 130px;
  height: auto;
}
.gold__solImg {
  width: 3vw;
  height: auto;
}
.gold__solpeepzImg {
  width: 80vw;
  height: auto;
}
.bkg {
  width: 70vw;
  height: auto;
}
.coolest {
  color: white;
  font-size: 4.6vh;
  position: relative;
  /* top: 3.5vh; */
  font-family: coolest;
  padding: 1vh;
  /* width: 100vw; */
}
.black {
  width: 100%;
  height: 7vh;
  background-color: black;
  position: relative;
  z-index: 100;
  top: 2vh;
}
.eyes {
  position: relative;
  top: -1vh;
  height: 30vh;
  background-image: linear-gradient(
    to bottom,
    #4b4a4a,
    #393838,
    #282727,
    #171717,
    #000000
  );
  background-size: cover;
}
.eye__img {
  width: 70vw;
  height: auto;
}

@media screen and (max-width: 800px) {
  .topbar {
    width: 100%;
    height: 50px !important;

    background-color: white;
  }
  .topbar__body {
    display: flex;
    height: 50px !important;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
  }
  .gold__solpeepz {
    margin-top: 10vh;
  }
  .top__solpeepz {
    width: 80px;
    height: auto;
    position: relative;
    left: 2vw !important;
  }
  .invite1 {
    position: absolute;
    top: 0.7vh !important;
    color: white;
    left: -3.2vw;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 1.1vh !important;
  }
  .invite3 {
    position: absolute;
    top: 0.7vh !important;
    color: white;
    left: -3.2vw;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 1.1vh !important;
  }
  .top__discordImg {
    position: relative;
    left: -1.8vw;
    width: 90px !important;
    height: auto;
  }
  .nav__disc {
    position: relative;
    left: -1.8vw;
    background-color: white;

    border-radius: 5vw;
    color: black;
    font-weight: 700;
    font-size: 8px !important;
    width: 90px;
    height: auto;
    /* padding:8px 0 8px 0; */
  }
  .top__discordImg2 {
    position: relative;
    left: -1.8vw;
    width: 90px !important;
    height: auto;
  }
  .top__discord {
    position: relative;
    top: 7px !important;
    margin-top: 0px;
  }
  .madfireImg {
    position: relative;
    width: 58px !important;
    height: auto;
    left: 0;
  }
  .centerText {
    position: relative;
    margin-top: 1.5vh;
  }
  .gold__solImg {
    width: 6vw;
    height: auto;
  }
  .gold__solpeepzImg {
    width: 80vw;
    height: auto;
  }
  .eyes {
    position: relative;
    top: -1vh;
    height: 20vh;
    background-image: linear-gradient(
      to bottom,
      #4b4a4a,
      #393838,
      #282727,
      #171717,
      #000000
    );
    background-size: cover;
  }
  .eye__img {
    width: 100vw;
    height: 25vh;
  }

  .coolest {
    color: white;
    font-size: 2vh;
    position: relative;
    top: 1vh;
    position: relative;
    /* margin-top: 5vh; */
    /* width: 100vw; */
  }
  .bkg {
    width: 90vw;
    height: auto;
  }
}

@media screen and (max-width: 320px) {
  .invite1 {
    font-size: 0.8vh !important;
    top: 9px !important;
    right: 3px;
  }
  .invite3 {
    font-size: 0.8vh !important;
    top: 9px !important;
    right: 3px;
  }
  .topbar {
    height: 48px !important;
  }
  .top__solpeepz {
    width: 60px;
    height: auto;
    position: relative;
    left: 0vw !important;
  }
  .nav__disc {
    width: 60px !important;
    height: auto;
    position: relative;
    font-size: 6px !important;
    left: 0vw !important;
  }
  .top__discordImg {
    position: relative;
    left: -2vw;
    width: 50px;
    height: auto;
  }

  .top__discordImg2 {
    position: relative;
    left: -2vw;
    width: 50px;
    height: auto;
  }
  .top__discord {
    position: relative;
    top: 6px !important ;
  }
  .madfireImg {
    position: relative;
    width: 45px !important;
    height: auto;
    left: 0;
  }
}

@media screen and (max-width: 1300px) {
  .top__solpeepz {
    position: relative;
    left: 0.5vw;
  }
}
@media screen and (min-width: 1400px) {
  .top__discord {
    top: 5px;
  }
}
@media screen and (max-width: 1400px) {
  .topbar {
    height: 65px;
  }
  .madfireImg {
    width: 80px;
  }
  .coolest {
    font-size: 4vh;
  }
  .top__discord {
    /* top:15px !important; */
  }
  .top__discord__footer {
  }
  .top__discordImg {
    /* width: 170px; */
    height: auto;
  }
  .top__discordImg2 {
    width: 170px;
    height: auto;
  }
  /* .invite1{
        font-size: 10px !important ;
        top: 0.2vh !important;
    } */
}
@media screen and (height: 1400px) {
  .topbar {
    height: 75px;
  }
  .madfireImg {
    width: 80px;
  }
  .coolest {
    font-size: 4vh;
  }
}
@media screen and (width: 1024px) {
  .topbar__body {
    width: 100%;
    height: 9vh;
  }
  .eyes {
    position: relative;
    top: -1vh;
    height: 20vh;
    background-image: linear-gradient(
      to bottom,
      #4b4a4a,
      #393838,
      #282727,
      #171717,
      #000000
    );
    background-size: cover;
  }
  .gold__solpeepz {
    margin-top: 5vh;
  }
  .coolest {
    color: white;
    font-size: 2vh;
    position: relative;
    /* width: 100vw; */
  }
  .eye__img {
    width: 100%;
    height: 30vh;
  }

  .topbar {
    width: 100%;
    height: 6vh;

    background-color: white;
  }
  .invite1 {
    font-size: 12px !important;
    left: 5.7vw;
    top: 10px !important;
  }
  .invite3 {
    font-size: 12px !important;
    left: 5.7vw;
    top: 15px !important;
  }
  .topbar__body {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    height: 6vh;
  }
  .top__solpeepz {
    width: 16vw;
    height: auto;
    position: relative;
    left: 4vw;
  }
  .top__discordImg {
    position: relative;
    left: 3vw;
    width: 16vw;
    height: auto;
  }
  .top__discordImg2 {
    position: relative;
    left: 3vw;
    width: 20vw !important;
    height: auto;
  }
  .top__discord {
    position: relative;
    top: 15px !important;
  }
  .madfireImg {
    position: relative;
    width: 10.9vw;
    height: auto;
    left: 0;
  }
  .centerText {
    position: relative;
    /* top: -0.4vh; */
    left: -2vw;
  }
  .gold__solpeepz {
    margin-top: 10vh;
  }
  .gold__solImg {
    width: 6vw;
    height: auto;
  }
  .gold__solpeepzImg {
    width: 80vw;
    height: auto;
  }
}

@media screen and (height: 812px) {
  .eye__img {
    width: 100vw;
    height: 20vh;
  }

  .coolest {
    color: white;
    font-size: 2vh !important;
    position: relative;
    /* width: 100vw; */
  }
  .bkg {
    width: 90vw;
    height: auto;
  }
  .invite1 {
    top: 5px !important;
    right: 4px;
  }
  .invite3 {
    top: 5px !important;
    right: 4px;
  }
}

@media screen and (width: 768px) {
  .top__discord {
    position: relative;
    /* top: 20px !important; */
    margin-top: 10px;
  }
  .topbar {
    height: 70px !important;
  }
  .invite1 {
    font-size: 0.9vh !important;
    top: 0.5vh !important;
    right: 0.6vw;
  }
  .invite3 {
    font-size: 0.9vh !important;
    top: 0.5vh !important;
    right: 0.6vw;
  }
  .centerText {
    position: relative;
    top: 0.5vh;
    left: -2vw;
  }
  .madfireImg {
    width: 80px !important;
    height: auto;
  }
  .top__solpeepz {
    width: 80px;
    height: auto;
  }
}

/* ---------------------------- */
@media screen and (max-width: 800px) and (max-height: 500px) {
  .madfireImg {
    width: 6vw !important;
    height: auto;
  }
  .top__solpeepz {
    width: 7vw;
    height: auto;
    position: relative;
    left: -0.5vw;
    bottom: 4vh;
  }
  .top__discordImg {
    position: relative;
    left: 3vw;
    width: 10vw;
    height: auto;
  }
  .top__discordImg2 {
    position: relative;
    left: 3vw;
    width: 10vw;
    height: auto;
  }
  .top__discord {
    position: relative;
    top: -1vh;
  }

  .eye__img {
    width: 100vw;
    height: 40vh;
  }
  .eyes {
    height: 30vh;
  }
}

@media screen and (height: 768px) {
  /* .madfireImg {
    width: 7vw !important;
    height: auto;
  }
  .top__solpeepz {
    width: 7vw;
    height: auto;
    position: relative;
    left: 0vw;
  } */
}

@media screen and (max-width: 360px) {
  .top__discord {
    /* margin-top: 5px !important; */
  }
}
@media screen and (width: 2560px) {
  .centerText {
    margin-top: 14px;
    position: relative;
    left: 0vw;
  }
}
